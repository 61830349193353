import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const PaymentMaintenancePage = () => (
    <Layout>
        <Seo title={'Make a payment'} description={''} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/help-with-repayments/dealing-with-difficult-times">Dealing
                            with difficult times</a></li>
                        <li><a href="/help-with-repayments/useful-information">Useful
                            information</a></li>
                        <li><a href="/help-with-repayments/budget-planner-intro">Budget
                            Planner</a></li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Make a payment</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading">We're sorry, we are currently carrying out essential maintenance on our
                            online payments page.</p>
                        <div>If&nbsp;you'd like to make a payment, please call us on 0800&nbsp;019 7415&nbsp;between
                            08:00 AM and 20:00 Monday - Friday. If you would like us to call you to take a payment over
                            the phone at a time to suit you, please request a call back.
                        </div>
                        <br />
                            <br />
                                <br />
                                    <div className="taR">
                                        <p><a
                                            href="/customer-service/callback-request"
                                            title="Call me back" className="btn btnApply purple">Call me back<span
                                            className="tl"></span><span className="tr"></span><span
                                            className="bl"></span><span className="br"></span></a></p>
                                    </div>
                    </div>
                </div>
                <div className="fbCol"></div>
            </section>
        </div>
    </Layout>
)

export default PaymentMaintenancePage;